import * as React from "react";

interface IProps {}

const DevInterface: React.FC<IProps> = ({}) => {
  return (
    <div className="h-screen w-full grid grid-cols-6 grid-rows-4 grid-flow-row gap-1 bg-gray-400">
      <div className="col-span-4 row-span-3">
        <div className="w-full h-full flex items-center justify-center p-4">
          <iframe className="w-full" style={{ aspectRatio: "16/9" }} src="/display"></iframe>
        </div>
      </div>
      <div className="col-span-2 row-span-3">
        <iframe className="w-full h-full" src="/manage"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button1|1"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button2|2"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button3|3"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button4|4"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button5|5"></iframe>
      </div>
      <div className="col-span-1 row-span-1">
        <iframe className="w-full h-full" src="/button#Button6|6"></iframe>
      </div>
    </div>
  );
};

export default DevInterface;
