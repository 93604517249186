import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from "@apollo/client";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { getMainDefinition } from "@apollo/client/utilities";
import { useMemo } from "react";

interface IProps {
  children: React.ReactNode;
}

const cable = createConsumer();

const GraphqlClient = (props: IProps) => {
  const apiUrl = window.location.host;

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: `/graphql`,
      credentials: "include",
    });

    const wsLink = new ActionCableLink({ cable });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      httpLink,
    );

    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
    });
  }, [apiUrl]);

  return <ApolloProvider client={client} {...props} />;
};

export default GraphqlClient;
