import "./style.scss";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GraphqlClient from "../graphql/GraphqlClient";
import DevPage from "../pages/DevPage";

const DisplayPage = React.lazy(() => import("../pages/DisplayPage"));
const HomePage = React.lazy(() => import("../pages/HomePage"));
const ManagerPage = React.lazy(() => import("../pages/ManagerPage"));
const ButtonPage = React.lazy(() => import("../pages/ButtonPage"));
const Atoms = React.lazy(() => import("./atoms"));

const App = () => (
  <GraphqlClient>
    <Suspense fallback={<div />}>
      <Router>
        <Routes>
          <Route path="dev" element={<DevPage />} />
          <Route path="manage/*" element={<ManagerPage />} />
          <Route path="display" element={<DisplayPage />} />
          <Route path="button" element={<ButtonPage />} />
          <Route path="atoms" element={<Atoms />} />
          <Route path="" element={<ButtonPage />} />
        </Routes>
      </Router>
    </Suspense>
    <div id="portal" />
  </GraphqlClient>
);

export default App;
